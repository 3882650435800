.whatsappbutton {
    display: block;
    width: 48px;
    height: 48px;
    padding: 4px;
    position: fixed;
    bottom: 24px;
    left: 24px;
    z-index: 99;
}

.whatsappbutton img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: white;
}

.callbutton {
    display: none;
    width: 65px;
    height: 65px;
    padding: 5px;
    position: fixed;
    left: 0;
    z-index: 99;
}

.callbutton img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: white;
}

@media (max-width: 450px) {
    .whatsappbutton {
        display: block;
        width: 48px;
        height: 48px;
        padding: 4px;
        position: fixed;
        bottom: 24px;
        left: 24px;
        z-index: 99;
    }

    .whatsappbutton img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: white;
    }

    .callbutton {
        display: block;
        width: 65px;
        height: 65px;
        padding: 5px;
        position: fixed;
        left: 0;
        z-index: 99;
    }

    .callbutton img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: white;
    }
}