@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ScriptMTBold";
  src: url("./ScriptMTBold.ttf"), format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MvBoli";
  src: url("./MvBoli.ttf"), format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Import Google Fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tomorrow:wght@100;200;300;400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* @import url("./ScriptMTBold.ttf"); */

/* Apply fonts to the body */
body {
  /* font-family: "Montserrat"; */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* Apply fonts to specific elements */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

p {
  font-family: "Montserrat";
}

/* Add more specific styling for other elements as needed */

/* Headings using script fonts */
h1.special-heading {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* Bold and modern fonts for emphasis */
strong {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* Elegance and tradition for quotes */
blockquote {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* Add any other custom styling for your website */

/* Reset default margin and padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
blockquote {
  margin: 0;
  padding: 0;
}


/* @media print {
  .fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }
} */