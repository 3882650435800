/* src/components/SearchBox.css */
@keyframes typingAnimation {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.placeholder-animation {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid black;
    /* Add a blinking cursor */
    animation: typingAnimation 3s steps(40) infinite alternate;
}

/* src/components/SearchBox.css */
/* Add styling for the suggestion box */
.search-results {
    position: absolute;
    top: 100%;
    /* Position below the search box */
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 100;
}