/* .productSliderStyle .carousel-control-prev{
    background: transparent !important;
}
.productSliderStyle .carousel-control-next{
    background: transparent !important;
}
.productSliderStyle .carousel-control-prev-icon{
    background-image: url('../../Images/left-arrow-next-svgrepo-com.svg') !important;
    height: 19px !important;
    opacity: 0.5;
    fill: rgb(66, 65, 63);
}
.productSliderStyle .carousel-control-prev-icon:hover{
    opacity: 1;
}

.productSliderStyle .carousel-control-next-icon{
    background-image: url('../../Images/left-arrow-next-svgrepo-com.svg') !important;
    height: 19px !important;
    rotate: 180deg;
    opacity: 0.5;
}

.productSliderStyle .carousel-control-next-icon:hover{
    opacity: 1;
} */

.productOptionsSlide .carousel-control-prev-icon {
    background-image: url('../../public/left-arrow-next-svgrepo-options-com.svg') !important;
}

.productOptionsSlide .carousel-control-next-icon {
    background-image: url('../../public/left-arrow-next-svgrepo-options-com.svg') !important;
    rotate: 180deg !important;
}